import React from "react";
import "./App.scss";
import Navigation from "./Navigation";
import { ContextProvider } from "./context/ContextProvider";

function App() {
  return (
    <ContextProvider>
      <div className="App">
        <Navigation />
      </div>
    </ContextProvider>
  );
}

export default App;
