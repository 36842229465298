import { Menu, MenuOpen } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { JWT_TOKEN, JWT_REFRESH_TOKEN } from "../api/axios";
import { useAppContext } from "../context/ContextProvider";

const Header = (props: {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}) => {
  const { isSidebarOpen, toggleSidebar } = props;
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAppContext();

  const logOut = () => {
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(JWT_REFRESH_TOKEN);
    setIsAuthenticated(false);
    navigate("/login");
  };

  const menuIconStyles = {
    color: "#05204A",
    marginLeft: 10,
    border: "1px solid #05204A",
    borderRadius: 0,
  };

  return (
    <div>
      <Navbar
        style={{ backgroundColor: "white" }}
        variant="light"
        expand="lg"
        collapseOnSelect
      >
        <IconButton
          disableRipple
          size="medium"
          sx={menuIconStyles}
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
        <Container>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={logOut}>Log out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
