import React, { FormEvent, useCallback, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  endpoints,
  JWT_TOKEN,
  setLocalStorageItem,
  JWT_REFRESH_TOKEN,
} from "../api/axios";
import axiosInstance from "../api/axiosClient";
import { useAppContext } from "../context/ContextProvider";
import FormContainer from "../components/FormContainer";

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { setIsAuthenticated } = useAppContext();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChangeLogin = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setLogin(e.target.value);
    },
    []
  );

  const handleChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setPassword(e.target.value);
    },
    []
  );

  const getUsers = async () => {
    setLocalStorageItem(JWT_TOKEN, "");
    setLocalStorageItem(JWT_REFRESH_TOKEN, "");
    setIsAuthenticated(false);
    setError("");
    return axiosInstance.post(endpoints.authURL, {
      username: login,
      password: password,
      // username: "test1@bcf-software.com",
      // password: "test",
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getUsers()
      .then((response) => {
        setLocalStorageItem(JWT_TOKEN, response!.data.jwtToken);
        setLocalStorageItem(JWT_REFRESH_TOKEN, response!.data.refreshToken);
        setIsAuthenticated(true);
      })
      .then(() => navigate("/", { replace: true }))
      .catch((error) => {
        setError(
          `Failed. Status code: ${error.response.data.status}  ${error.response.data.message}`
        );
      });
  };

  return (
    <FormContainer>
      <h1 className="my-4">Login</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 my-4" controlId="formBasiclogin">
          <Form.Label>Login</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter login"
            value={login}
            onChange={handleChangeLogin}
          />
        </Form.Group>

        <Form.Group className="mb-3 my-4" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleChangePassword}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </FormContainer>
  );
};

export default Login;
