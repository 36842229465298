import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  CandidateModalProps,
  SelectedCandidate,
} from "../ts/interfaces/app_interfaces";
import { Seniority, Status } from "../ts/enums/app_enums";
import { getCandidateData } from "../api/axiosRequests";
import { useAppContext } from "../context/ContextProvider";
import TitleSpan from "./UI/TitleSpan";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "auto",
  p: 4,
};

function isFieldEmpty(value: any) {
  if (value === null || value == undefined) return true;
  const stringValue = String(value);
  return stringValue.trim().length < 1;
}

const emptyCandidate: SelectedCandidate = {
  id: "",
  name: "",
  email: "",
  profession: "",
  status: Status.ToVerify,
  seniority: Seniority.Junior,
  dateOfBirth: "",
  phoneNumber: "",
  postalCode: "",
  state: "",
  country: "",
  city: "",
  certification: "",
  publication: "",
  summary: "",
  totalYearsOfExperience: "",
};

export default function CandidateDetails(props: CandidateModalProps) {
  const { open, handleCloseModal, candidateId } = props;
  const [selectedCandidate, setSelectedCandidate] =
    useState<SelectedCandidate>(emptyCandidate);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    let isMounted = true;
    if (isAuthenticated && candidateId && open) {
      getCandidateData(Number(candidateId)).then((data) => {
        if (isMounted) setSelectedCandidate(data);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [candidateId, open]);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-panel" sx={style}>
        <h2 id="parent-modal-title">CANDIDATE DETAILS</h2>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.name)}>
            Name:
          </TitleSpan>
          {selectedCandidate.name}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.email)}>
            Email:
          </TitleSpan>
          {selectedCandidate.email}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.phoneNumber)}>
            Phone Number:
          </TitleSpan>
          {selectedCandidate.phoneNumber}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.postalCode)}>
            Postal Code:
          </TitleSpan>
          {selectedCandidate.postalCode}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.state)}>
            State:
          </TitleSpan>
          {selectedCandidate.state}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.country)}>
            Country:
          </TitleSpan>
          {selectedCandidate.country}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.city)}>
            City:
          </TitleSpan>
          {selectedCandidate.city}
        </p>
        <p>
          <TitleSpan
            isBodyEmpty={isFieldEmpty(selectedCandidate.certification)}
          >
            Certification:
          </TitleSpan>
          {selectedCandidate.certification}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.publication)}>
            Publication:
          </TitleSpan>
          {selectedCandidate.publication}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.summary)}>
            Summary:
          </TitleSpan>
          {selectedCandidate.summary}
        </p>
        <p>
          <TitleSpan
            isBodyEmpty={isFieldEmpty(selectedCandidate.totalYearsOfExperience)}
          >
            Total Years Of Experience:
          </TitleSpan>
          {selectedCandidate.totalYearsOfExperience}
        </p>
        <p>
          <TitleSpan isBodyEmpty={isFieldEmpty(selectedCandidate.profession)}>
            Profession:
          </TitleSpan>
          {selectedCandidate.profession}
        </p>
      </Box>
    </Modal>
  );
}
