import React, {
  useState,
  useEffect,
  ChangeEvent,
  ChangeEventHandler,
} from "react";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Data,
  EnhancedTableProps,
  HeadCell,
} from "../ts/interfaces/app_interfaces";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useDebounce } from "use-debounce";
import { useAppContext } from "../context/ContextProvider";
import { Status, Seniority, CoderbyteStatus } from "../ts/enums/app_enums";

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "profession",
    numeric: false,
    disablePadding: false,
    label: "Profession",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "seniority",
    numeric: false,
    disablePadding: false,
    label: "Seniority",
  },
  {
    id: "coderbyteStatus",
    numeric: false,
    disablePadding: false,
    label: "Coderbyte Status",
  },
];

export function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const [filterValue, setFilterValue] = useState("");
  const debouncedFilterValue = useDebounce(filterValue, 500);
  const [statusValue, setStatusValue] = useState("");
  const [seniorityValue, setSeniorityValue] = useState("");
  const [coderbyteStatusValue, setCoderbyteStatusValue] = useState("");
  const { setColumnToFilter, setValueToFilter } = useAppContext();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    headCellId: string
  ) => {
    setFilterValue(e.target.value);
    setColumnToFilter(headCellId);
  };

  const handleChangeStatus = (e: any) => {
    setStatusValue(e.target.value);
    setFilterValue(e.target.value);
    setColumnToFilter("status");
  };

  const handleChangeSeniority = (e: any) => {
    setSeniorityValue(e.target.value);
    setFilterValue(e.target.value);
    setColumnToFilter("seniority");
  };

  const handleChangeCoderbyteStatus = (e: any) => {
    setCoderbyteStatusValue(e.target.value);
    setFilterValue(e.target.value);
    setColumnToFilter("coderbyteStatus");
  };

  useEffect(
    () => setValueToFilter(debouncedFilterValue[0]),
    [debouncedFilterValue]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div className="column-name">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ flexDirection: "row", alignItems: "flex-start" }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
              <div className="column-input-container">
                <FilterListIcon className="filter-icon" />
                {(() => {
                  switch (headCell.label) {
                    case "Status":
                      return (
                        <div className="filter-container">
                          {statusValue && (
                            <Button
                              className="btn-filters"
                              variant="text"
                              onClick={() => {
                                setStatusValue("");
                                setColumnToFilter("");
                              }}
                            >
                              X
                            </Button>
                          )}
                          <Select
                            value={statusValue}
                            onChange={handleChangeStatus}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            key="status"
                          >
                            <MenuItem
                              value={Status.Approved}
                              className="approved"
                            >
                              {Status.Approved}
                            </MenuItem>
                            <MenuItem
                              value={Status.Deleted}
                              className="deleted"
                            >
                              {Status.Deleted}
                            </MenuItem>
                            <MenuItem
                              value={Status.ToVerify}
                              className="to-verify"
                            >
                              {Status.ToVerify}
                            </MenuItem>
                          </Select>
                        </div>
                      );
                    case "Seniority":
                      return (
                        <div className="filter-container">
                          {seniorityValue && (
                            <Button
                              className="btn-filters"
                              variant="text"
                              onClick={() => {
                                setSeniorityValue("");
                                setColumnToFilter("");
                              }}
                            >
                              X
                            </Button>
                          )}
                          <Select
                            value={seniorityValue}
                            onChange={handleChangeSeniority}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            key="seniority"
                          >
                            <MenuItem value={Seniority.Junior}>
                              {Seniority.Junior}
                            </MenuItem>
                            <MenuItem value={Seniority.Middle}>
                              {Seniority.Middle}
                            </MenuItem>
                            <MenuItem value={Seniority.Senior}>
                              {Seniority.Senior}
                            </MenuItem>
                          </Select>
                        </div>
                      );
                    case "Coderbyte Status":
                      return (
                        <div className="filter-container">
                          {coderbyteStatusValue && (
                            <Button
                              className="btn-filters"
                              variant="text"
                              onClick={() => {
                                setCoderbyteStatusValue("");
                                setColumnToFilter("");
                              }}
                            >
                              X
                            </Button>
                          )}
                          <Select
                            value={coderbyteStatusValue}
                            onChange={handleChangeCoderbyteStatus}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            key="seniority"
                          >
                            <MenuItem value={CoderbyteStatus.TEST_DONE}>
                              {CoderbyteStatus.TEST_DONE}
                            </MenuItem>
                            <MenuItem value={CoderbyteStatus.INVITED}>
                              {CoderbyteStatus.INVITED}
                            </MenuItem>
                          </Select>
                        </div>
                      );
                    default:
                      return (
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          className="filter-field"
                          style={{ marginLeft: 27 }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e, headCell.id)
                          }
                        />
                      );
                  }
                })()}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
