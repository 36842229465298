import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { ListGroup } from "react-bootstrap";
import { CoderByteTestResponse } from "../ts/interfaces/app_interfaces";
import { IconButton } from "@mui/material";

function AsanaTaskList(props: {
  data: CoderByteTestResponse[];
  onItemClick: (task: CoderByteTestResponse | null) => void;
}) {
  return (
    <ListGroup as="ol" numbered>
      {props.data.map((task) => {
        return (
          <ListGroup.Item
            key={task.id}
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">{task.technology}</div>
              {task.url}
            </div>
            <IconButton onClick={() => props.onItemClick(task)}>
              <EditIcon />
            </IconButton>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

export default AsanaTaskList;
