import React, { FormEvent, useState } from "react";
import { Form } from "react-bootstrap";
import {
  CoderByteTest,
  CoderByteTestResponse,
} from "../../ts/interfaces/app_interfaces";
import axiosInstance from "../../api/axiosClient";
import { endpoints } from "../../api/axios";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function EditTask(props: {
  task: CoderByteTestResponse;
  goBack: () => void;
}) {
  const { task, goBack } = props;
  const [error, setError] = useState(false);
  const [technology, setTechnology] = useState(task.technology);
  const [url, setUrl] = useState(task.url);
  const isFormValid = technology.trim() !== "" && url.trim() !== "";

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submitTask = async () => {
      setError(false);
      const newTask: CoderByteTest = {
        technology: technology,
        url: url,
      };
      const apiUrl = endpoints.coderbyteTest;
      if (task.id === "") await axiosInstance.post(apiUrl, newTask);
      else await axiosInstance.patch(`${apiUrl}/${task.id}`, newTask);
    };

    submitTask()
      .then(() => goBack())
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const handleDelete = () => {
    const deleteTask = async () => {
      setError(false);
      await axiosInstance.delete(`${endpoints.coderbyteTest}/${task.id}`);
    };

    if (task.id !== "")
      deleteTask()
        .then(() => goBack())
        .catch((err) => {
          setError(true);
          console.log(err);
        });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 my-4">
        <Form.Label>Technology name :</Form.Label>
        <Form.Control
          style={{ textAlign: "left" }}
          id="technology"
          value={technology}
          placeholder={task.technology}
          onChange={(e) => setTechnology(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4">
        <Form.Label>URL :</Form.Label>
        <Form.Control
          style={{ textAlign: "left" }}
          id="url"
          value={url}
          placeholder={task.url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </Form.Group>
      {error && (
        <p className="error-message">
          Something went wrong ! Check your data and try again...
        </p>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            type="submit"
            className="my-4"
            color="success"
            disabled={!isFormValid}
          >
            {task.id ? "Update" : "Create"}
          </Button>
          <Button type="button" className="my-4 mx-4" onClick={goBack}>
            Cancel
          </Button>
        </div>
        {task.id !== "" && (
          <Button
            type="button"
            color="error"
            className="my-4"
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        )}
      </div>
    </Form>
  );
}
