import { NativeFormControlElement } from "@mui/base/FormControlUnstyled/FormControlUnstyled.types";
import { Button, FormControlLabel, Switch } from "@mui/material";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { endpoints } from "../../../api/axios";
import axiosInstance from "../../../api/axiosClient";
import { WorkExperience } from "../../../ts/interfaces/app_interfaces";

const emptyWork = {
  jobTitle: "",
  jobDescription: "",
  organization: "",
  postalCode: "",
  state: "",
  country: "",
  city: "",
  isCurrent: false,
  startDate: "",
  monthsInPosition: "",
  endDate: "",
  candidateId: -1,
};

export default function EditWorkExperience(props: {
  workId: number | undefined;
  candidateId: number;
  closeEditView: () => void;
}) {
  const { candidateId, workId, closeEditView } = props;
  const [selectedWork, setSelectedWork] = useState<WorkExperience>(emptyWork);
  const [jobTitle, setJobTitle] = useState(emptyWork.jobTitle);
  const [jobDescription, setJobDescription] = useState(
    emptyWork.jobDescription
  );
  const [organization, setOrganization] = useState(emptyWork.organization);
  const [postalCode, setPostalCode] = useState(emptyWork.postalCode);
  const [state, setState] = useState(emptyWork.state);
  const [country, setCountry] = useState(emptyWork.country);
  const [city, setCity] = useState(emptyWork.city);
  const [isCurrent, setIsCurrent] = useState<undefined | boolean>(
    emptyWork.isCurrent
  );
  const [monthsInPosition, setMonthsInPosition] = useState(
    emptyWork.monthsInPosition
  );
  const [startDate, setStartDate] = useState(emptyWork.startDate);
  const [endDate, setEndDate] = useState(emptyWork.endDate);

  const isSubmmitDisabled = useMemo(
    () =>
      (!organization && !selectedWork.organization) ||
      (!jobTitle && !selectedWork.jobTitle) ||
      (!startDate && !selectedWork.startDate) ||
      (!isCurrent && !endDate && !selectedWork.endDate) ||
      (!jobDescription && !selectedWork.jobDescription) ||
      (isCurrent === undefined && !selectedWork.isCurrent),

    [
      organization,
      selectedWork.organization,
      jobTitle,
      selectedWork.jobTitle,
      startDate,
      selectedWork.startDate,
      endDate,
      selectedWork.endDate,
      jobDescription,
      selectedWork.jobDescription,
      isCurrent,
      selectedWork.isCurrent,
    ]
  );

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getWork = async (id: number) => {
      try {
        const url = `${endpoints.experiences}/${id}`;
        const response = await axiosInstance.get(url);
        if (isMounted) {
          const work = response.data;
          setSelectedWork(work);
          setStartDate(work.startDate);
          setIsCurrent(work.isCurrent);
          setEndDate(work.endDate);
        }
      } catch (err) {
        console.error("No such work", err);
      }
    };
    if (isMounted && workId) getWork(workId);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [workId]);

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<NativeFormControlElement>,
      setValue: (value: React.SetStateAction<string>) => void
    ) => {
      e.preventDefault();
      setValue(e.target.value);
    },
    []
  );

  const removeWork = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      const removeWorkExperience = async () => {
        try {
          const url = `${endpoints.experiences}/${workId}`;
          await axiosInstance.delete(url);
        } catch (err) {
          console.error("No work Experience", err);
        }
      };
      if (workId) removeWorkExperience().then(() => closeEditView());
    },
    [workId, closeEditView]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitWork = async (newWork: WorkExperience) => {
      try {
        let url = endpoints.experiences;
        if (workId) await axiosInstance.patch(`${url}/${workId}`, newWork);
        else await axiosInstance.post(url, newWork);
      } catch (err) {
        console.error("No such experience to patch");
      }
    };
    const newWork = {
      jobTitle: jobTitle || selectedWork.jobTitle,
      jobDescription: jobDescription || selectedWork.jobDescription,
      organization: organization || selectedWork.organization,
      postalCode: postalCode || selectedWork.postalCode,
      state: state || selectedWork.state,
      country: country || selectedWork.country,
      city: city || selectedWork.city,
      isCurrent: isCurrent || false,
      monthsInPosition: monthsInPosition || selectedWork.monthsInPosition,
      startDate: startDate || selectedWork.startDate,
      endDate: endDate || selectedWork.endDate,
      candidateId,
    };
    submitWork(newWork).then(() => closeEditView());
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpJobTitle">
        <Form.Label>Title</Form.Label>
        <Form.Control
          placeholder={selectedWork.jobTitle || emptyWork.jobTitle}
          onChange={(e) => handleInputChange(e, setJobTitle)}
          className={jobTitle || selectedWork.jobTitle ? "" : "to-edition"}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpJobDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          placeholder={selectedWork.jobDescription || emptyWork.jobDescription}
          onChange={(e) => handleInputChange(e, setJobDescription)}
          className={
            jobDescription || selectedWork.jobDescription ? "" : "to-edition"
          }
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpOrganization">
        <Form.Label>Organization</Form.Label>
        <Form.Control
          placeholder={selectedWork.organization || emptyWork.organization}
          onChange={(e) => handleInputChange(e, setOrganization)}
          className={
            organization || selectedWork.organization ? "" : "to-edition"
          }
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpPostalCode">
        <Form.Label>Postal code</Form.Label>
        <Form.Control
          placeholder={selectedWork.postalCode || emptyWork.postalCode}
          onChange={(e) => handleInputChange(e, setPostalCode)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpState">
        <Form.Label>State</Form.Label>
        <Form.Control
          placeholder={selectedWork.state || emptyWork.state}
          onChange={(e) => handleInputChange(e, setState)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpCountry">
        <Form.Label>Country</Form.Label>
        <Form.Control
          placeholder={selectedWork.country || emptyWork.country}
          onChange={(e) => handleInputChange(e, setCountry)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpCity">
        <Form.Label>City</Form.Label>
        <Form.Control
          placeholder={selectedWork.city || emptyWork.city}
          onChange={(e) => handleInputChange(e, setCity)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpMonthsInPosition">
        <Form.Label>Months in position</Form.Label>
        <Form.Control
          type="number"
          placeholder={
            selectedWork.monthsInPosition || emptyWork.monthsInPosition
          }
          onChange={(e) => handleInputChange(e, setMonthsInPosition)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpStartDate">
        <Form.Label>Start date</Form.Label>
        <Form.Control
          type="date"
          value={startDate || emptyWork.startDate}
          onChange={(e) => handleInputChange(e, setStartDate)}
          className={startDate || selectedWork.startDate ? "" : "to-edition"}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formWorkExpEndDate">
        <Form.Label
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          End date
          <FormControlLabel
            control={<Switch checked={isCurrent || emptyWork.isCurrent} />}
            label="in progress"
            labelPlacement="start"
            onChange={(e) => setIsCurrent((prev) => !prev)}
            className={
              !isCurrent && (endDate || selectedWork.endDate)
                ? ""
                : "to-edition"
            }
          />
        </Form.Label>
        {!isCurrent && (
          <Form.Control
            type="date"
            value={endDate || emptyWork.endDate}
            onChange={(e) => handleInputChange(e, setEndDate)}
            className={endDate || selectedWork.endDate ? "" : "to-edition"}
          />
        )}
      </Form.Group>
      <div className="edit-form-button-group">
        <div>
          <Button
            className="my-4"
            color="success"
            type="submit"
            disabled={isSubmmitDisabled}
          >
            Submit
          </Button>
          <Button type="button" onClick={() => closeEditView()}>
            CANCEL
          </Button>
        </div>
        {workId && (
          <Button type="button" color="error" onClick={removeWork}>
            Delete Work Experience
          </Button>
        )}
      </div>
    </Form>
  );
}
