import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormContainer from "../FormContainer";
import { CandidateModalProps } from "../../ts/interfaces/app_interfaces";
import { Tab, Tabs } from "react-bootstrap";
import EditCandidateData from "./EditCandidateData";
import EducationList from "./educations/EducationList";
import SkillList from "./skills/SkillList";
import WorkExperienceList from "./workExperience/WorkExperienceList";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditCandidate(props: CandidateModalProps) {
  const { open, handleCloseModal, candidateId } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-container edit-candidate"
      >
        <Box className="modal-panel" sx={style}>
          <FormContainer>
            <h1 className="my-4 edit-candidate-title">EDIT CANDIDATE</h1>
            <Tabs
              unmountOnExit
              mountOnEnter
              defaultActiveKey="candidateData"
              id="edit-candidate-tab"
              style={{ justifyContent: "center", flexWrap: "nowrap" }}
            >
              <Tab eventKey="candidateData" title="Candidate">
                <EditCandidateData
                  handleEditUser={handleCloseModal}
                  candidateId={Number(candidateId)}
                />
              </Tab>
              <Tab eventKey="candidateWorkExperience" title="Work Experience">
                <WorkExperienceList candidateId={Number(candidateId)} />
              </Tab>
              <Tab eventKey="candidateEducations" title="Educations">
                <EducationList candidateId={Number(candidateId)} />
              </Tab>
              <Tab eventKey="candidateSkills" title="Skills">
                <SkillList candidateId={Number(candidateId)} />
              </Tab>
            </Tabs>
          </FormContainer>
        </Box>
      </Modal>
    </div>
  );
}
