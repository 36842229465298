import React from "react";

export default function TitleSpan(props: {
  isBodyEmpty: boolean;
  children: string;
}) {
  return (
    <span
      className="user-details-title"
      style={{ color: props.isBodyEmpty ? "red" : "" }}
    >
      {props.children}
    </span>
  );
}
