import React, { FormEvent, useState } from "react";
import { Box, CircularProgress, Paper } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import axiosInstance from "../api/axiosClient";
import { endpoints } from "../api/axios";
import { useNavigate } from "react-router-dom";
import { PersonAddAltTwoTone } from "@mui/icons-material";

const UploadCandidate = () => {
  const [candidateFile, setCandidateFile] = useState<File | null>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    setCandidateFile(file);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const postFile = async () => {
      setErrorMessage("");
      const formData = new FormData();
      formData.append("file", candidateFile!);
      const response = await axiosInstance.post(endpoints.parse, formData);

      if (response.status === 200) {
        setCandidateFile(null);
      }
    };
    postFile()
      .then(() => navigate("/"))
      .catch((err) => {
        const message = err?.response?.data?.message;
        setErrorMessage(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box sx={{ maxWidth: 800, textAlign: "left" }}>
      <Paper sx={{ p: 4, minHeight: 310 }}>
        <h2 style={{ display: "flex", alignItems: "center" }}>
          {<PersonAddAltTwoTone sx={{ width: 30, height: 30, mr: 2 }} />}
          New candidate
        </h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 my-4">
            <Form.Label>Upload file (pdf,doc,docx) :</Form.Label>
            <Form.Control
              style={{ textAlign: "left" }}
              name="upload-photo"
              type="file"
              onChange={handleChange}
            />
          </Form.Group>
          {errorMessage.length > 0 && (
            <p className="error-message">
              {`Failed while parsing file! ${errorMessage}. `}
            </p>
          )}
          <div
            style={{ display: "flex", justifyContent: "end", marginTop: 40 }}
          >
            <Button
              variant={`${candidateFile ? "success" : "primary"}`}
              type="submit"
              disabled={!candidateFile}
            >
              Add new candidate
            </Button>
            {loading && <CircularProgress style={{ marginLeft: 25 }} />}
          </div>
        </Form>
      </Paper>
    </Box>
  );
};

export default UploadCandidate;
