import React, { createContext, useState, useContext } from 'react'
import { getLocalStorageItem, JWT_TOKEN } from '../api/axios'

interface AppContextValues {
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  totalPages: number
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  columnToFilter: string
  setColumnToFilter: React.Dispatch<React.SetStateAction<string>>
  valueToFilter: string
  setValueToFilter: React.Dispatch<React.SetStateAction<string>>
  isCandidateChecked: boolean
  setIsCandidateChecked: React.Dispatch<React.SetStateAction<boolean>>
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
}

export const AppContext = createContext<undefined | AppContextValues>(undefined)
const Provider = AppContext.Provider

interface Props {
  children: React.ReactNode
}

export const ContextProvider = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!getLocalStorageItem(JWT_TOKEN)
  )
  const [totalPages, setTotalPages] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [columnToFilter, setColumnToFilter] = useState('')
  const [valueToFilter, setValueToFilter] = useState('')
  const [isCandidateChecked, setIsCandidateChecked] = useState(false)
  const [selected, setSelected] = useState('')

  return (
    <Provider
      value={{
        setIsAuthenticated,
        isAuthenticated,
        totalPages,
        setTotalPages,
        currentPage,
        setCurrentPage,
        columnToFilter,
        setColumnToFilter,
        valueToFilter,
        setValueToFilter,
        isCandidateChecked,
        setIsCandidateChecked,
        selected,
        setSelected,
      }}
    >
      {children}
    </Provider>
  )
}

export function useAppContext() {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppContext should be used within an ContextProvider')
  }
  return context
}
