import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useAppContext } from "../context/ContextProvider";

export default function PaginationButtons() {
  const { setCurrentPage, totalPages } = useAppContext();
  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <Stack spacing={2} className="page-numer-list">
      <Pagination
        count={totalPages}
        showFirstButton
        showLastButton
        onChange={(event, page) => handleChange(event, page)}
      />
    </Stack>
  );
}
