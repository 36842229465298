import { Box, styled } from "@mui/material";
import React, { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useAppContext } from "./ContextProvider";

const sidebarWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${sidebarWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const contentStyle = {
  backgroundColor: "#EBEBEB",
  padding: 3,
  minHeight: "100vh",
};

export default function UserContext() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const { isAuthenticated } = useAppContext();

  return isAuthenticated ? (
    <Box sx={{ display: "flex" }}>
      <Sidebar width={sidebarWidth} open={isSidebarOpen} />
      <Main open={isSidebarOpen} style={{ overflow: "auto" }}>
        <Header
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={() => setIsSidebarOpen((prev) => !prev)}
        />
        <Box sx={contentStyle}>
          <Outlet />
        </Box>
      </Main>
    </Box>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
