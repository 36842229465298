import React, {
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
  useCallback,
} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { endpoints } from "../api/axios";
import axiosInstance from "../api/axiosClient";
import { useAppContext } from "../context/ContextProvider";
import { EnhancedTableToolbar } from "../components/EnhancedTableToolbar";
import { EnhancedTableHead } from "../components/EnhancedTableHead";
import { CandidateRow, Data } from "../ts/interfaces/app_interfaces";
import { Order, NumOfRows } from "../ts/types/app_types";
import PaginationButtons from "../components/Pagination";
import PaginationDropdown from "../components/PaginationDropdown";
import { CoderbyteStatus, Status } from "../ts/enums/app_enums";
import useRefreshToken from "../hooks/useRefreshToken";
import { PeopleAltTwoTone } from "@mui/icons-material";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Home() {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("name");
  const {
    isAuthenticated,
    currentPage,
    columnToFilter,
    valueToFilter,
    isCandidateChecked,
    selected,
    setSelected,
    setTotalPages,
  } = useAppContext();
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [rows, setRows] = useState<CandidateRow[]>([]);
  useRefreshToken();

  useEffect(() => {
    const getRows = async () => {
      try {
        let response;

        if (valueToFilter && columnToFilter) {
          const url = `${
            endpoints.candidates
          }/search?pageSize=${rowsPerPage}&pageNumber=${currentPage - 1}`;
          response = await axiosInstance.post(url, {
            [columnToFilter]: valueToFilter,
          });
        } else {
          const url = `${
            endpoints.candidates
          }?pageSize=${rowsPerPage}&pageNumber=${currentPage - 1}`;
          response = await axiosInstance.get(url);
        }
        if (response.data) {
          setTotalPages(response.data.size);
          setRows(response.data.content);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (isAuthenticated) getRows();
  }, [
    columnToFilter,
    valueToFilter,
    rowsPerPage,
    currentPage,
    isAuthenticated,
    isCandidateChecked,
    selected,
    setTotalPages,
  ]);

  const handleChangeRowsPerPage = useCallback((numOfRows: NumOfRows) => {
    setRowsPerPage(numOfRows);
  }, []);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected("");
  };

  const handleClick = (event: MouseEvent<unknown>, row: Data) => {
    setSelected(selected === row.id ? "" : row.id);
  };

  const isSelected = (id: string) => selected === id;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    currentPage > 0
      ? Math.max(0, (1 + currentPage) * rowsPerPage - rows.length)
      : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{ width: "100%", mb: 2, textAlign: "left", p: 4 }}
        className="candidates-container"
      >
        <EnhancedTableToolbar
          numSelected={String(selected).length}
          candidate={rows.filter((row) => row.id === selected)}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(
                //   currentPage * rowsPerPage,
                //   currentPage * rowsPerPage + rowsPerPage
                // )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>

                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.profession}</TableCell>
                      <TableCell
                        align="left"
                        className={row.status.toLowerCase()}
                      >
                        {row.status === Status.ToVerify
                          ? "TO VERIFY"
                          : row.status}
                      </TableCell>
                      <TableCell align="left">{row.seniority}</TableCell>
                      <TableCell align="left">
                        {row.coderbyteStatus === CoderbyteStatus.TEST_DONE
                          ? "TEST DONE"
                          : row.coderbyteStatus}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={7} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pagination-container">
          <PaginationDropdown
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <PaginationButtons />
        </div>
      </Paper>
    </Box>
  );
}
