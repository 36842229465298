import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { baseURL, getHeader } from './axios'

const config: AxiosRequestConfig = {
  baseURL,
}

export const axiosInstance = axios.create(config)

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = (await getHeader(config.url || '')) as AxiosRequestHeaders
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
