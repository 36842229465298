import { Add, Edit } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { getSkills } from "../../../api/axiosRequests";
import { useAppContext } from "../../../context/ContextProvider";
import { SkillResponse } from "../../../ts/interfaces/app_interfaces";
import EditSkill from "./EditSkill";

function parseDateToYear(date: string) {
  if (date === "now") return new Date().getFullYear();
  return new Date(date).getFullYear();
}

export default function SkillList(props: { candidateId: number }) {
  const { candidateId } = props;
  const [skills, setSkills] = useState<SkillResponse[]>([]);
  const [selectedSkillId, setSelectedSkillId] = useState<number | undefined>();
  const [isEditView, setIsEditView] = useState(false);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    let isMounted = true;
    if (isAuthenticated) {
      getSkills(candidateId).then((data) => {
        if (isMounted) setSkills(data);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, isEditView, candidateId]);

  const addSkill = useCallback((e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    setSelectedSkillId(undefined);
    setIsEditView(true);
  }, []);

  const handleEditButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, skillId: number) => {
      e.preventDefault();
      setSelectedSkillId(skillId);
      setIsEditView(true);
    },
    []
  );

  if (isEditView)
    return (
      <EditSkill
        candidateId={candidateId}
        skillId={selectedSkillId}
        closeEditView={() => setIsEditView(false)}
      />
    );
  else
    return (
      <ListGroup className="my-4">
        {skills
          .sort((skill1, skill2) => {
            //TODO CHECK DATE BEFORE PASS IT TO MOMMENT
            return -moment(skill1.lastUsed).diff(skill2.lastUsed);
          })
          .map((skill, index) => {
            const lastUsed = parseDateToYear(skill.lastUsed) || "";
            return (
              <ListGroupItem
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <b>{`Last used: ${skill.lastUsed}`}</b>
                <p
                  style={{
                    margin: "auto 10px",
                    textAlign: "justify",
                  }}
                >
                  {skill.skillName}
                </p>
                <div style={{ display: "flex" }}>
                  <IconButton
                    onClick={(e) => handleEditButtonClick(e, skill.id)}
                  >
                    <Edit />
                  </IconButton>
                </div>
              </ListGroupItem>
            );
          })}
        <Button
          onClick={addSkill}
          variant="text"
          size="large"
          startIcon={<Add />}
        >
          Add
        </Button>
      </ListGroup>
    );
}
