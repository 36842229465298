import { Add, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { endpoints } from "../../../api/axios";
import axiosInstance from "../../../api/axiosClient";
import { getEducations } from "../../../api/axiosRequests";
import { useAppContext } from "../../../context/ContextProvider";
import { EducationResponse } from "../../../ts/interfaces/app_interfaces";
import { isEducationApproved } from "../../../utils/isApproved";
import EditEducation from "./EditEducation";

function parseDateToYear(date: string) {
  if (date === "now") return new Date().getFullYear();
  return new Date(date).getFullYear();
}

export default function EducationList(props: { candidateId: number }) {
  const { candidateId } = props;
  const [educations, setEducations] = useState<EducationResponse[]>([]);
  const [selectedEducationId, setSelectedEducationId] = useState<
    number | undefined
  >();
  const [isEditView, setIsEditView] = useState(false);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    let isMounted = true;
    if (isAuthenticated) {
      getEducations(candidateId).then((data) => {
        if (isMounted) setEducations(data);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, isEditView, candidateId]);

  const addEducation = useCallback((e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    setSelectedEducationId(undefined);
    setIsEditView(true);
  }, []);

  const removeEducation = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      educationId: number
    ) => {
      e.preventDefault();
      const removeEducation = async () => {
        try {
          const url = `${endpoints.educations}/${educationId}`;
          const response = await axiosInstance.delete(url);
        } catch (err) {
          console.error("No educations", err);
        }
      };
      if (isAuthenticated) removeEducation();
    },
    [isAuthenticated]
  );

  const handleEditButtonClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      educationId: number
    ) => {
      e.preventDefault();
      setSelectedEducationId(educationId);
      setIsEditView(true);
    },
    []
  );

  if (isEditView)
    return (
      <EditEducation
        candidateId={candidateId}
        educationId={selectedEducationId}
        closeEditView={() => setIsEditView(false)}
      />
    );
  else
    return (
      <ListGroup className="my-4">
        {educations
          .sort((education1, education2) => {
            //TODO CHECK DATE BEFORE PASS IT TO MOMMENT
            if (education1.isCurrent) return -1;
            else if (education2.isCurrent) return 1;
            console.log(
              "DATE",
              education1.completionDate,
              education2.completionDate
            );

            return -moment(education1.completionDate).diff(
              education2.completionDate
            );
          })
          .map((education, index) => {
            const marked = !isEducationApproved(education);
            const yearFrom = parseDateToYear(education.startDate) || "from";
            const yearTo = education.isCurrent
              ? "now"
              : parseDateToYear(education.completionDate) || "to";
            return (
              <ListGroupItem
                key={index}
                className={marked ? "marked " : ""}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <b>{`${yearFrom}-${yearTo}`}</b>
                <p
                  style={{
                    margin: "auto 10px",
                    textAlign: "justify",
                  }}
                >
                  {education.organization}
                </p>
                <div style={{ display: "flex" }}>
                  <IconButton
                    onClick={(e) => handleEditButtonClick(e, education.id)}
                  >
                    <Edit />
                  </IconButton>
                </div>
              </ListGroupItem>
            );
          })}
        <Button
          onClick={addEducation}
          variant="text"
          size="large"
          startIcon={<Add />}
        >
          Add
        </Button>
      </ListGroup>
    );
}
