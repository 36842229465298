import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Props } from '../ts/interfaces/app_interfaces'

const FormContainer = ({ children }: Props) => {
  return (
    <Container className="py-3">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          {children}
        </Col>
      </Row>
    </Container>
  )
}

export default FormContainer
