import { NativeFormControlElement } from "@mui/base/FormControlUnstyled/FormControlUnstyled.types";
import { Button } from "@mui/material";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { endpoints } from "../../../api/axios";
import axiosInstance from "../../../api/axiosClient";
import { Skill } from "../../../ts/interfaces/app_interfaces";

const emptySkill = {
  skillName: "",
  lastUsed: "",
  numberOfMonths: "",
  type: "",
  candidateId: -1,
};

export default function EditSkill(props: {
  skillId: number | undefined;
  candidateId: number;
  closeEditView: () => void;
}) {
  const { candidateId, skillId, closeEditView } = props;
  const [selectedSkill, setSelectedSkill] = useState<Skill>(emptySkill);
  const [skillName, setSkillName] = useState(emptySkill.skillName);
  const [lastUsed, setLastUsed] = useState(emptySkill.lastUsed);
  const [numberOfMonths, setNumberOfMonths] = useState(
    emptySkill.numberOfMonths
  );
  const [type, setType] = useState(emptySkill.type);

  const isSubmmitDisabled = useMemo(
    () => !skillName && !selectedSkill.skillName,
    [skillName, selectedSkill.skillName]
  );

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getSkill = async (id: number) => {
      try {
        const url = `${endpoints.skills}/${id}`;
        const response = await axiosInstance.get(url);
        if (isMounted) {
          const skill = response.data;
          setSelectedSkill(skill);
          setLastUsed(skill.lastUsed);
        }
      } catch (err) {
        console.error("No such skill", err);
      }
    };
    if (isMounted && skillId) getSkill(skillId);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [skillId]);

  const removeSkill = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const removeSkill = async () => {
        try {
          const url = `${endpoints.skills}/${skillId}`;
          await axiosInstance.delete(url);
        } catch (err) {
          console.error("No Skill", err);
        }
      };
      if (skillId) removeSkill().then(() => closeEditView());
    },
    []
  );

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<NativeFormControlElement>,
      setValue: (value: React.SetStateAction<string>) => void
    ) => {
      e.preventDefault();
      setValue(e.target.value);
    },
    []
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitSkill = async (newSkill: Skill) => {
      try {
        let url = endpoints.skills;
        if (skillId) await axiosInstance.patch(`${url}/${skillId}`, newSkill);
        else await axiosInstance.post(url, newSkill);
        closeEditView();
      } catch (err) {
        console.error("No such skill to patch/post");
      }
    };
    const newSkill = {
      skillName: skillName || selectedSkill.skillName,
      lastUsed: lastUsed || selectedSkill.lastUsed,
      numberOfMonths: numberOfMonths || selectedSkill.numberOfMonths,
      type: type || selectedSkill.type,
      candidateId,
    };
    submitSkill(newSkill);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 my-4" controlId="formSkillName">
        <Form.Label>Skill name</Form.Label>
        <Form.Control
          placeholder={selectedSkill.skillName || emptySkill.skillName}
          onChange={(e) => handleInputChange(e, setSkillName)}
          className={skillName || selectedSkill.skillName ? "" : "to-edition"}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formSkillType">
        <Form.Label>Type</Form.Label>
        <Form.Control
          placeholder={selectedSkill.type || emptySkill.type}
          onChange={(e) => handleInputChange(e, setType)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formSkillLastUsed">
        <Form.Label>Last used</Form.Label>
        <Form.Control
          type="date"
          value={lastUsed || emptySkill.lastUsed}
          onChange={(e) => handleInputChange(e, setLastUsed)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formSkillNbrOfMonth">
        <Form.Label>Number of months</Form.Label>
        <Form.Control
          type="number"
          placeholder={
            selectedSkill.numberOfMonths || emptySkill.numberOfMonths
          }
          onChange={(e) => handleInputChange(e, setNumberOfMonths)}
        />
      </Form.Group>
      <div className="edit-form-button-group">
        <div>
          <Button
            className="my-4"
            color="success"
            type="submit"
            disabled={isSubmmitDisabled}
          >
            Submit
          </Button>
          <Button type="button" onClick={() => closeEditView()}>
            CANCEL
          </Button>
        </div>
        {skillId && (
          <Button type="button" color="error" onClick={removeSkill}>
            Delete Skill
          </Button>
        )}
      </div>
    </Form>
  );
}
