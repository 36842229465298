import { Add, Edit } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { getWorkExperience } from '../../../api/axiosRequests'
import { useAppContext } from '../../../context/ContextProvider'
import { WorkExperienceResponse } from '../../../ts/interfaces/app_interfaces'
import EditWorkExperience from './EditWorkExperience'
import { isWorkExperienceApproved } from '../../../utils/isApproved'

function parseDateToYear(date: string) {
  if (date === 'now') return new Date().getFullYear()
  return new Date(date).getFullYear()
}

export default function WorkExperienceList(props: { candidateId: number }) {
  const { candidateId } = props
  const [workExperience, setWorkExperience] = useState<
    WorkExperienceResponse[]
  >([])
  const [selectedWorkId, setSelectedWorkId] = useState<number | undefined>()
  const [isEditView, setIsEditView] = useState(false)
  const { isAuthenticated } = useAppContext()

  useEffect(() => {
    let isMounted = true
    if (isAuthenticated) {
      getWorkExperience(candidateId).then((data) => {
        if (isMounted) setWorkExperience(data)
      })
    }
    return () => {
      isMounted = false
    }
  }, [isAuthenticated, isEditView, candidateId])

  const addWork = useCallback((e: React.MouseEvent<unknown>) => {
    e.preventDefault()
    setSelectedWorkId(undefined)
    setIsEditView(true)
  }, [])

  const handleEditButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, workId: number) => {
      e.preventDefault()
      setSelectedWorkId(workId)
      setIsEditView(true)
    },
    []
  )

  if (isEditView)
    return (
      <EditWorkExperience
        candidateId={candidateId}
        workId={selectedWorkId}
        closeEditView={() => setIsEditView(false)}
      />
    )
  else
    return (
      <ListGroup className="my-4">
        {workExperience
          .sort((work1, work2) => {
            //TODO CHECK DATE BEFORE PASS IT TO MOMMENT
            if (work1.isCurrent) return -1
            else if (work2.isCurrent) return 1
            return -moment(work1.endDate).diff(work2.endDate)
          })
          .map((work, index) => {
            const marked = !isWorkExperienceApproved(work)
            const yearFrom = parseDateToYear(work.startDate) || 'from'
            const yearTo = work.isCurrent
              ? 'now'
              : parseDateToYear(work.endDate) || 'to'
            return (
              <ListGroupItem
                key={index}
                className={marked ? 'marked ' : ''}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <b>{`${yearFrom}-${yearTo}`}</b>
                <p
                  style={{
                    margin: 'auto 10px',
                    textAlign: 'justify',
                  }}
                >
                  {work.organization}
                </p>
                <div style={{ display: 'flex' }}>
                  <IconButton
                    onClick={(e) => handleEditButtonClick(e, work.id)}
                  >
                    <Edit />
                  </IconButton>
                </div>
              </ListGroupItem>
            )
          })}
        <Button
          onClick={addWork}
          variant="text"
          size="large"
          startIcon={<Add />}
        >
          Add
        </Button>
      </ListGroup>
    )
}
