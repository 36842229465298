import { NativeFormControlElement } from '@mui/base/FormControlUnstyled/FormControlUnstyled.types'
import { Button } from '@mui/material'
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { endpoints } from '../../api/axios'
import axiosInstance from '../../api/axiosClient'
import { getCandidateData } from '../../api/axiosRequests'
import { useAppContext } from '../../context/ContextProvider'
import { Seniority, Status } from '../../ts/enums/app_enums'
import {
  Candidate,
  SelectedCandidate,
} from '../../ts/interfaces/app_interfaces'

const emptyCandidate: SelectedCandidate = {
  id: '',
  name: '',
  email: '',
  profession: '',
  status: Status.ToVerify,
  seniority: undefined,
  dateOfBirth: '',
  phoneNumber: '',
  postalCode: '',
  state: '',
  country: '',
  city: '',
  certification: '',
  publication: '',
  summary: '',
  totalYearsOfExperience: '',
}

export default function EditCandidateData(props: {
  candidateId: number
  handleEditUser: () => void
}) {
  const { candidateId, handleEditUser } = props
  const [selectedCandidate, setSelectedCandidate] =
    useState<SelectedCandidate>(emptyCandidate)
  const [name, setName] = useState(emptyCandidate.name)
  const [dateOfBirth, setDateOfBirth] = useState(emptyCandidate.dateOfBirth)
  const [email, setEmail] = useState(emptyCandidate.email)
  const [phoneNumber, setPhoneNumber] = useState(emptyCandidate.phoneNumber)
  const [postalCode, setPostalCode] = useState(emptyCandidate.postalCode)
  const [state, setState] = useState(emptyCandidate.state)
  const [country, setCountry] = useState(emptyCandidate.country)
  const [city, setCity] = useState(emptyCandidate.city)
  const [certification, setCertification] = useState(
    emptyCandidate.certification
  )
  const [publication, setPublication] = useState(emptyCandidate.publication)
  const [summary, setSummary] = useState(emptyCandidate.summary)
  const [totalYearsOfExperience, setTotalYearsOfExperience] = useState(
    emptyCandidate.totalYearsOfExperience
  )
  const [profession, setProfession] = useState(emptyCandidate.profession)
  const [seniority, setSeniority] = useState<Seniority | undefined>(
    emptyCandidate.seniority
  )
  const { isAuthenticated, setIsCandidateChecked } = useAppContext()

  let seniorityToggleColor
  if (seniority === 'JUNIOR') seniorityToggleColor = 'success'
  else if (seniority === 'MIDDLE') seniorityToggleColor = 'warning'
  else if (seniority === 'SENIOR') seniorityToggleColor = 'danger'
  else seniorityToggleColor = 'dark'

  const isSubmmitDisabled = useMemo(
    () =>
      (!name && !selectedCandidate.name) ||
      (!profession && !selectedCandidate.profession) ||
      (!summary && !selectedCandidate.summary) ||
      (!seniority && !selectedCandidate.seniority),

    [
      name,
      selectedCandidate.name,
      profession,
      selectedCandidate.profession,
      summary,
      selectedCandidate.summary,
      seniority,
      selectedCandidate.seniority,
    ]
  )

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    if (candidateId && isAuthenticated) {
      getCandidateData(Number(candidateId)).then((candidate) => {
        if (isMounted) {
          setSelectedCandidate(candidate)
          setSeniority(candidate.seniority)
          setDateOfBirth(candidate.dateOfBirth)
        }
      })
    }
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [isAuthenticated])

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<NativeFormControlElement>,
      setValue: (value: React.SetStateAction<string>) => void
    ) => {
      e.preventDefault()
      setValue(e.target.value)
    },
    []
  )

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const patchCandidateData = async (candidate: Candidate) => {
      try {
        const response = await axiosInstance.patch(
          `${endpoints.candidates}/${candidateId}`,
          candidate
        )
        console.log(response)
      } catch (err) {
        console.error('No such candidate')
      }
    }

    if (candidateId && isAuthenticated) {
      const candidate: Candidate = {
        name: name || selectedCandidate.name,
        email: email || selectedCandidate.email,
        profession: profession || selectedCandidate.profession,
        seniority: seniority ? seniority : selectedCandidate.seniority,
        dateOfBirth: dateOfBirth || selectedCandidate.dateOfBirth,
        phoneNumber: phoneNumber || selectedCandidate.phoneNumber,
        postalCode: postalCode || selectedCandidate.postalCode,
        state: state || selectedCandidate.state,
        country: country || selectedCandidate.country,
        city: city || selectedCandidate.city,
        certification: certification || selectedCandidate.certification,
        publication: publication || selectedCandidate.publication,
        summary: summary || selectedCandidate.summary,
        totalYearsOfExperience:
          totalYearsOfExperience || selectedCandidate.totalYearsOfExperience,
      }
      patchCandidateData(candidate).then(() =>
        setIsCandidateChecked((old) => !old)
      )
    }
    handleEditUser()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 my-4" controlId="formBasicName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="name"
          onChange={(e) => handleInputChange(e, setName)}
          placeholder={selectedCandidate.name || emptyCandidate.name}
          className={name || selectedCandidate.name ? '' : 'to-edition'}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicSeniority">
        <Form.Label>Seniority</Form.Label>
        <Dropdown
          className={
            seniority || selectedCandidate.seniority
              ? ''
              : 'candidate-seniority-dropdown to-edition'
          }
        >
          <Dropdown.Toggle variant={seniorityToggleColor}>
            {seniority || emptyCandidate.seniority || 'NONE'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={(e) => setSeniority(Seniority.Junior)}>
              JUNIOR
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => setSeniority(Seniority.Middle)}>
              MIDDLE
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => setSeniority(Seniority.Senior)}>
              SENIOR
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicDateOfBirth">
        <Form.Label>Date of Birth</Form.Label>
        <Form.Control
          type="date"
          onChange={(e) => handleInputChange(e, setDateOfBirth)}
          value={dateOfBirth || emptyCandidate.dateOfBirth}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="lastName"
          placeholder={selectedCandidate.email || emptyCandidate.email}
          onChange={(e) => handleInputChange(e, setEmail)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicPhoneNumber">
        <Form.Label>Phone number</Form.Label>
        <Form.Control
          type="phoneNumber"
          placeholder={
            selectedCandidate.phoneNumber || emptyCandidate.phoneNumber
          }
          onChange={(e) => handleInputChange(e, setPhoneNumber)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicPostalCode">
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          type="postalCode"
          placeholder={
            selectedCandidate.postalCode || emptyCandidate.postalCode
          }
          onChange={(e) => handleInputChange(e, setPostalCode)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicState">
        <Form.Label>State</Form.Label>
        <Form.Control
          type="state"
          placeholder={selectedCandidate.state || emptyCandidate.state}
          onChange={(e) => handleInputChange(e, setState)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicCountry">
        <Form.Label>Country</Form.Label>
        <Form.Control
          type="country"
          placeholder={selectedCandidate.country || emptyCandidate.country}
          onChange={(e) => handleInputChange(e, setCountry)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicPostalCity">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="city"
          placeholder={selectedCandidate.city || emptyCandidate.city}
          onChange={(e) => handleInputChange(e, setCity)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicCertification">
        <Form.Label>Certification</Form.Label>
        <Form.Control
          type="certification"
          placeholder={
            selectedCandidate.certification || emptyCandidate.certification
          }
          onChange={(e) => handleInputChange(e, setCertification)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicPublication">
        <Form.Label>Publication</Form.Label>
        <Form.Control
          type="publication"
          placeholder={
            selectedCandidate.publication || emptyCandidate.publication
          }
          onChange={(e) => handleInputChange(e, setPublication)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicSummary">
        <Form.Label>Summary</Form.Label>
        <Form.Control
          type="summary"
          placeholder={selectedCandidate.summary || emptyCandidate.summary}
          onChange={(e) => handleInputChange(e, setSummary)}
          className={summary || selectedCandidate.summary ? '' : 'to-edition'}
        />
      </Form.Group>
      <Form.Group
        className="mb-3 my-4"
        controlId="formBasicTotalYearsOfExperience"
      >
        <Form.Label>Total Years Of Experience</Form.Label>
        <Form.Control
          type="totalYearsOfExperience"
          placeholder={
            selectedCandidate.totalYearsOfExperience ||
            emptyCandidate.totalYearsOfExperience
          }
          onChange={(e) => handleInputChange(e, setTotalYearsOfExperience)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formBasicProfession">
        <Form.Label>Profession</Form.Label>
        <Form.Control
          type="profession"
          placeholder={
            selectedCandidate.profession || emptyCandidate.profession
          }
          onChange={(e) => handleInputChange(e, setProfession)}
          className={
            profession || selectedCandidate.profession ? '' : 'to-edition'
          }
        />
      </Form.Group>
      <div className="edit-form-button-group">
        <Button
          className="my-4"
          color="success"
          type="submit"
          disabled={isSubmmitDisabled}
        >
          Submit
        </Button>
        <Button type="button" onClick={handleEditUser}>
          CANCEL
        </Button>
      </div>
    </Form>
  )
}
