import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useEffect } from 'react'
import axios, { endpoints, JWT_REFRESH_TOKEN } from '../api/axios'
import {
  getLocalStorageItem,
  JWT_TOKEN,
  setLocalStorageItem,
} from '../api/axios'
import { useAppContext } from '../context/ContextProvider'

const useRefreshToken = () => {
  const { isAuthenticated, setIsAuthenticated } = useAppContext()
  const refreshToken = getLocalStorageItem(JWT_REFRESH_TOKEN)

  const accessTokenIsExpired = () => {
    const token = getLocalStorageItem(JWT_TOKEN)
    const decoded: JwtPayload = jwtDecode(token as string)
    const tokenExpired = (decoded!.exp as number) * 1000 < Date.now()

    return tokenExpired && isAuthenticated
  }

  useEffect(() => {
    const refreshTokenAction = async () => {
      if (accessTokenIsExpired()) {
        const response = await axios.post(endpoints.refreshToken, {
          refreshToken,
        })
        if (response) {
          setLocalStorageItem(JWT_TOKEN, response!.data.jwtToken)
          setLocalStorageItem(JWT_REFRESH_TOKEN, response!.data.refreshToken)
        } else {
          setIsAuthenticated(false)
        }
      }
    }

    refreshTokenAction()
  }, [])
}

export default useRefreshToken
