import React, { useCallback, useState } from "react";
import CandidateDetails from "./CandidateDetails";
import Toolbar from "@mui/material/Toolbar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import { EnhancedTableToolbarProps } from "../ts/interfaces/app_interfaces";
import { Download } from "@mui/icons-material";
import EditCandidate from "./candidate/EditCandidate";
import { endpoints } from "../api/axios";
import axiosInstance from "../api/axiosClient";
import { Avatar, Box, Button, Menu, MenuItem, Modal } from "@mui/material";
import PostTask from "./PostTask";
import { FileFormat, Status } from "../ts/enums/app_enums";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { confirmBoxStyle } from "../utils/const";
import { useAppContext } from "../context/ContextProvider";

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, candidate } = props;
  const candidateData = candidate[0];
  const [editCandidate, setEditCandidate] = React.useState(false);
  const [showCandidateDetails, setShowCandidateDetails] = React.useState(false);
  const [openPostTask, setOpenPostTask] = React.useState(false);
  const [downloadEl, setDownloadEl] = React.useState<null | HTMLElement>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const openDownload = Boolean(downloadEl);
  const { selected, setSelected } = useAppContext();

  const handleSwitchModal = useCallback(
    (showModalFunction: (value: React.SetStateAction<boolean>) => void) => {
      showModalFunction((prev) => !prev);
    },
    []
  );

  const handleOpenDeleteModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setOpenDeleteModal(false);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDeleteModal(false);
    const deleteCandidate = async () => {
      try {
        const url = endpoints.deleteCandidate;
        await axiosInstance.delete(`${url}/${selected}`);
        setSelected("");
      } catch (err) {
        console.error("Error exporting candidate", err);
      }
    };
    deleteCandidate();
  }, [selected, setSelected]);

  const handleDownloadResume = useCallback(
    (format: FileFormat) => {
      const exportCandidate = async (fileFormat: FileFormat) => {
        try {
          const url =
            fileFormat === FileFormat.PDF
              ? endpoints.exportPDF
              : endpoints.exportDOCX;
          await axiosInstance
            .get(`${url}/${selected}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              const headerLine = response.headers["content-disposition"];
              const startFileNameIndex = headerLine.indexOf('"') + 1;
              const endFileNameIndex = headerLine.lastIndexOf('"');
              const filename = headerLine.substring(
                startFileNameIndex,
                endFileNameIndex
              );
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            });
        } catch (err) {
          console.error("Error exporting candidate", err);
        }
      };
      exportCandidate(format).finally(() => setDownloadEl(null));
    },
    [selected]
  );

  return (
    candidateData && (
      <>
        <EditCandidate
          open={editCandidate}
          handleCloseModal={() => handleSwitchModal(setEditCandidate)}
          candidateId={selected}
        />
        <CandidateDetails
          open={showCandidateDetails}
          handleCloseModal={() => handleSwitchModal(setShowCandidateDetails)}
          candidateId={selected}
        />
        <PostTask
          open={openPostTask}
          handleCloseModal={() => handleSwitchModal(setOpenPostTask)}
          candidateId={selected}
        />

        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          {selected && (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
              className="user-subtitle"
            >
              {candidateData.name}
            </Typography>
          )}
          {numSelected > 0 && (
            <div className="user-icons">
              <Tooltip
                className="user-icon"
                title="User details"
                onClick={() => handleSwitchModal(setShowCandidateDetails)}
              >
                <IconButton>
                  <PersonIcon />
                </IconButton>
              </Tooltip>
              {candidateData.status === Status.Approved && (
                <>
                  {/* // <Tooltip
                //   className="user-icon"
                //   title="Download resume"
                //   onClick={handleDownloadResume}
                //   onClick={handleDownload}
                // > */}
                  <IconButton
                    aria-controls={openDownload ? "download-menu" : undefined}
                    aria-expanded={openDownload ? "true" : undefined}
                    aria-haspopup="true"
                    aria-label="more"
                    id="download-button"
                    onClick={(event) => setDownloadEl(event.currentTarget)}
                  >
                    <Download />
                  </IconButton>
                  <Menu
                    id="download-menu"
                    MenuListProps={{
                      "aria-labelledby": "download-button",
                    }}
                    anchorEl={downloadEl}
                    open={openDownload}
                    onClose={() => setDownloadEl(null)}
                  >
                    <MenuItem
                      onClick={() => handleDownloadResume(FileFormat.PDF)}
                    >
                      Download as pdf
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDownloadResume(FileFormat.WORD_DOCX)}
                    >
                      Download as docx
                    </MenuItem>
                  </Menu>
                </>
                // </Tooltip>
              )}
              {candidateData.status === Status.Approved &&
                !candidateData.coderbyteStatus && (
                  <Tooltip
                    className="user-icon"
                    title="Create task on Asana"
                    onClick={() => handleSwitchModal(setOpenPostTask)}
                  >
                    <IconButton>
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        src="coderbyte_icon.avif"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              <Tooltip
                className="user-icon"
                title="Edit Candidate"
                onClick={() => handleSwitchModal(setEditCandidate)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {candidateData.status !== Status.Deleted && (
                <Tooltip
                  className="delete-icon"
                  title="Delete Candidate"
                  onClick={handleOpenDeleteModal}
                >
                  <IconButton>
                    <DeleteTwoToneIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Modal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={confirmBoxStyle}>
                  <Typography id="modal-modal-title">
                    Delete Candidate
                  </Typography>
                  <Button variant="contained" onClick={handleClickDelete}>
                    Confirm
                  </Button>
                </Box>
              </Modal>
            </div>
          )}
        </Toolbar>
      </>
    )
  );
};
