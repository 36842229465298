import React, { useCallback, useEffect, useState } from "react";
import FormContainer from "../components/FormContainer";
import { Form, Button } from "react-bootstrap";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isPasswordMatches, setIsPasswordMatches] = useState(false);

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setEmail(e.target.value);
    },
    []
  );
  const handleChangeFirstName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setFirstName(e.target.value);
    },
    []
  );

  const handleChangeLastName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setLastName(e.target.value);
    },
    []
  );
  const handleChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setPassword(e.target.value);
    },
    []
  );
  const handleChangeConfirmPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setConfirmPassword(e.target.value);
    },
    []
  );
  const handleSubmit = useCallback(() => {}, []); //todo

  const validatePassword = useCallback(() => {
    setIsPasswordMatches(!!confirmPassword && password === confirmPassword);
  }, [confirmPassword, password]);

  useEffect(() => {
    validatePassword();
  }, [confirmPassword, validatePassword]);

  const isSubmitDisabled =
    !isPasswordMatches || !email || !password || !lastName || !firstName;

  return (
    <FormContainer>
      <h1 className="my-4">Sign up</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 my-4" controlId="formBasicName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="firstName"
            placeholder="Enter your first name"
            onChange={handleChangeFirstName}
          />
        </Form.Group>
        <Form.Group className="mb-3 my-4" controlId="formBasicName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="lastName"
            placeholder="Enter your last name"
            onChange={handleChangeLastName}
          />
        </Form.Group>
        <Form.Group className="mb-3 my-4" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            onChange={handleChangeEmail}
          />
        </Form.Group>

        <Form.Group className="mb-3 my-4" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={handleChangePassword}
          />
        </Form.Group>
        <Form.Group className="mb-3 my-4" controlId="formBasicPasswordRepeat">
          <Form.Label>Repeat your password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Repeat your password"
            onChange={handleChangeConfirmPassword}
          />
          {!isPasswordMatches && confirmPassword && (
            <span className="err">
              Password and Confirm Password does not match.
            </span>
          )}
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isSubmitDisabled}>
          Submit
        </Button>
      </Form>
    </FormContainer>
  );
};

export default Signup;
