import { Add } from "@mui/icons-material";
import { Avatar, Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { getCoderByteTests } from "../../api/axiosRequests";
import AsanaTaskList from "../../components/AsanaTaskList";
import { CoderByteTestResponse } from "../../ts/interfaces/app_interfaces";
import EditTask from "./EditTask";

const emptyTask: CoderByteTestResponse = {
  id: "",
  technology: "",
  url: "",
};

export default function Asana() {
  const [tasks, setTasks] = useState<CoderByteTestResponse[]>([]);
  const [selectedTask, setSelectedTask] =
    useState<CoderByteTestResponse | null>(null);
  const [error, setError] = useState(false);

  const title = `Asana - ${
    selectedTask
      ? selectedTask.technology.length > 0
        ? "Edit task"
        : "New task"
      : "Tasks"
  }`;

  const updateTasks = useCallback(() => {
    setError(false);
    getCoderByteTests()
      .then((response) => setTasks(response))
      .catch((err) => setError(true));
  }, []);

  useEffect(() => {
    if (selectedTask === null) {
      updateTasks();
    }
  }, [selectedTask]);

  const errorMessage = (
    <p
      style={{
        height: 150,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "grey",
      }}
    >
      {error ? (
        <>
          Something went wrong! <Button onClick={updateTasks}>Try again</Button>
        </>
      ) : (
        <>
          There is not any task yet.
          <Button onClick={() => setSelectedTask(emptyTask)}>
            Create one
          </Button>{" "}
          ?
        </>
      )}
    </p>
  );

  return (
    <Box sx={{ maxWidth: 1000, textAlign: "left" }}>
      <Paper sx={{ p: 4, minHeight: 310 }}>
        <h2 style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
          <Avatar sx={{ width: 30, height: 30, mr: 2 }} src="asana_icon.avif" />
          {title}
        </h2>
        {error || tasks.length === 0 ? (
          errorMessage
        ) : (
          <>
            {selectedTask ? (
              <EditTask
                task={selectedTask}
                goBack={() => setSelectedTask(null)}
              />
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <AsanaTaskList
                  data={tasks}
                  onItemClick={(task) => setSelectedTask(task)}
                />
                <Button
                  onClick={() => setSelectedTask(emptyTask)}
                  variant="text"
                  size="large"
                  startIcon={<Add />}
                >
                  Add
                </Button>
              </div>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
}
