import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import UserContext from "./context/UserContext";
import Home from "./pages/Home";
import UploadCandidate from "./pages/UploadCandidate";
import Asana from "./pages/asana/Asana";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route element={<UserContext />}>
          <Route path="/" element={<Home />} />
          <Route path="/uploadCV" element={<UploadCandidate />} />
          <Route path="/asana" element={<Asana />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
