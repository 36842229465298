export enum Status {
  Approved = "APPROVED",
  ToVerify = "TO_VERIFY",
  Deleted = "DELETED",
}

export enum Seniority {
  Junior = "JUNIOR",
  Middle = "MIDDLE",
  Senior = "SENIOR",
}

export enum CoderbyteStatus {
  INVITED = "INVITED",
  TEST_DONE = "TEST_DONE",
}

export enum FileFormat {
  PDF = "PDF",
  WORD_DOCX = "WORD_DOCX",
}
