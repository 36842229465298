import { AddTask, TableChart, UploadFile } from "@mui/icons-material";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DrawerHeader = styled("a")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  marginBottom: 35,
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  textDecoration: "none",
  fontSize: 20,
  fontWeight: "bold",
  color: "white",
  ":hover": {
    color: "white",
  },
}));

const iconStyle = {
  color: "white",
};

export default function Sidebar(props: { open: boolean; width: number }) {
  const { open, width } = props;
  const navigate = useNavigate();

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "#05204A",
          color: "white",
        },
      }}
      sx={{
        width: width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: width,
          boxSizing: "border-box",
        },
      }}
      anchor={"left"}
      open={open}
      variant="persistent"
    >
      <DrawerHeader href="#home">
        <Image src="https://bcf-software.pl/img/logo.svg" height={40} />
        <span>Application Name</span>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon sx={iconStyle}>
              <TableChart />
            </ListItemIcon>
            <ListItemText primary="Candidates" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/uploadCV")}>
            <ListItemIcon sx={iconStyle}>
              <UploadFile />
            </ListItemIcon>
            <ListItemText primary="Upload CV" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/asana")}>
            <ListItemIcon sx={iconStyle}>
              <AddTask />
            </ListItemIcon>
            <ListItemText primary="Update tasks on Asana" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
