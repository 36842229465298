import React, { useCallback, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { PaginationDropdownProps } from '../ts/interfaces/app_interfaces'

const PaginationDropdown = ({
  handleChangeRowsPerPage,
}: PaginationDropdownProps) => {
  const [numOfRows, setNumOfRows] = useState(8)
  const handleChange = useCallback((e: any) => {
    setNumOfRows(e.target.value)
    handleChangeRowsPerPage(e.target.value)
  }, [])
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={numOfRows}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={11}>11</MenuItem>
      </Select>
      <FormHelperText>Number of candidates</FormHelperText>
    </FormControl>
  )
}

export default PaginationDropdown
