import { NativeFormControlElement } from "@mui/base/FormControlUnstyled/FormControlUnstyled.types";
import { Button, FormControlLabel, Switch } from "@mui/material";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { endpoints } from "../../../api/axios";
import axiosInstance from "../../../api/axiosClient";
import { Education } from "../../../ts/interfaces/app_interfaces";

const emptyEducation: Education = {
  organization: "",
  postalCode: "",
  state: "",
  country: "",
  city: "",
  educationName: "",
  educationLevel: "",
  completionDate: "",
  isCurrent: false,
  startDate: "",
  candidateId: -1,
};

export default function EditEducation(props: {
  educationId: number | undefined;
  candidateId: number;
  closeEditView: () => void;
}) {
  const { candidateId, educationId, closeEditView } = props;
  const [selectedEducation, setSelectedEducation] =
    useState<Education>(emptyEducation);
  const [organization, setOrganization] = useState(emptyEducation.organization);
  const [postalCode, setPostalCode] = useState(emptyEducation.postalCode);
  const [state, setState] = useState(emptyEducation.state);
  const [country, setCountry] = useState(emptyEducation.country);
  const [city, setCity] = useState(emptyEducation.city);
  const [educationName, setEducationName] = useState(
    emptyEducation.educationName
  );
  const [educationLevel, setEducationLevel] = useState(
    emptyEducation.educationLevel
  );
  const [startDate, setStartDate] = useState(emptyEducation.startDate);
  const [isCurrent, setIsCurrent] = useState(emptyEducation.isCurrent);
  const [completionDate, setCompletionDate] = useState(
    emptyEducation.completionDate
  );

  const isSubmmitDisabled = useMemo(
    () =>
      (!educationName && !selectedEducation.educationName) ||
      (!organization && !selectedEducation.organization) ||
      (!startDate && !selectedEducation.startDate) ||
      (!isCurrent && !completionDate && !selectedEducation.completionDate),
    [
      educationName,
      selectedEducation.educationName,
      organization,
      selectedEducation.organization,
      startDate,
      selectedEducation.startDate,
      completionDate,
      selectedEducation.completionDate,
      isCurrent,
      selectedEducation.isCurrent,
    ]
  );

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getEducation = async (id: number) => {
      try {
        const url = `${endpoints.educations}/${id}`;
        const response = await axiosInstance.get(url);
        if (isMounted) {
          const education = response.data;
          setSelectedEducation(education);
          setStartDate(education.startDate);
          setIsCurrent(education.isCurrent);
          setCompletionDate(education.completionDate);
        }
      } catch (err) {
        console.error("No such education", err);
      }
    };
    if (isMounted && educationId) getEducation(educationId);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [educationId]);

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<NativeFormControlElement>,
      setValue: (value: React.SetStateAction<string>) => void
    ) => {
      e.preventDefault();
      setValue(e.target.value);
    },
    []
  );

  const removeEducation = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      const removeEducation = async () => {
        try {
          const url = `${endpoints.educations}/${educationId}`;
          await axiosInstance.delete(url);
        } catch (err) {
          console.error("No education", err);
        }
      };
      if (educationId) removeEducation().then(() => closeEditView());
    },
    [educationId, closeEditView]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitEducation = async (newEducation: Education) => {
      try {
        const url = endpoints.educations;
        if (educationId)
          await axiosInstance.patch(`${url}/${educationId}`, newEducation);
        else await axiosInstance.post(url, newEducation);
        closeEditView();
      } catch (err) {
        console.error("No such education to patch");
      }
    };
    const newEducation = {
      organization: organization || selectedEducation.organization,
      postalCode: postalCode || selectedEducation.postalCode,
      state: state || selectedEducation.state,
      country: country || selectedEducation.country,
      city: city || selectedEducation.city,
      educationName: educationName || selectedEducation.educationName,
      educationLevel: educationLevel || selectedEducation.educationLevel,
      startDate: startDate || selectedEducation.startDate,
      isCurrent: isCurrent || false,
      completionDate: completionDate || selectedEducation.completionDate,
      candidateId,
    };
    submitEducation(newEducation);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 my-4" controlId="formEducationOrganization">
        <Form.Label>Organization</Form.Label>
        <Form.Control
          placeholder={
            selectedEducation.organization || emptyEducation.organization
          }
          onChange={(e) => handleInputChange(e, setOrganization)}
          className={
            organization || selectedEducation.organization ? "" : "to-edition"
          }
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationPostalCode">
        <Form.Label>Postal code</Form.Label>
        <Form.Control
          placeholder={
            selectedEducation.postalCode || emptyEducation.postalCode
          }
          onChange={(e) => handleInputChange(e, setPostalCode)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationState">
        <Form.Label>State</Form.Label>
        <Form.Control
          placeholder={selectedEducation.state || emptyEducation.state}
          onChange={(e) => handleInputChange(e, setState)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationCountry">
        <Form.Label>Country</Form.Label>
        <Form.Control
          placeholder={selectedEducation.country || emptyEducation.country}
          onChange={(e) => handleInputChange(e, setCountry)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationCity">
        <Form.Label>City</Form.Label>
        <Form.Control
          placeholder={selectedEducation.city || emptyEducation.city}
          onChange={(e) => handleInputChange(e, setCity)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationName">
        <Form.Label>Education name</Form.Label>
        <Form.Control
          placeholder={
            selectedEducation.educationName || emptyEducation.educationName
          }
          onChange={(e) => handleInputChange(e, setEducationName)}
          className={
            educationName || selectedEducation.educationName ? "" : "to-edition"
          }
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationLevel">
        <Form.Label>Education level</Form.Label>
        <Form.Control
          placeholder={
            selectedEducation.educationLevel || emptyEducation.educationLevel
          }
          onChange={(e) => handleInputChange(e, setEducationLevel)}
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationYear">
        <Form.Label>Start date</Form.Label>
        <Form.Control
          type="date"
          value={startDate || emptyEducation.startDate}
          onChange={(e) => handleInputChange(e, setStartDate)}
          className={
            startDate || selectedEducation.startDate ? "" : "to-edition"
          }
        />
      </Form.Group>
      <Form.Group className="mb-3 my-4" controlId="formEducationYear">
        <Form.Label
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          End date
          <FormControlLabel
            control={<Switch checked={isCurrent || emptyEducation.isCurrent} />}
            label="in progress"
            labelPlacement="start"
            onChange={(e) => setIsCurrent((prev) => !prev)}
          />
        </Form.Label>
        {!isCurrent && (
          <Form.Control
            type="date"
            value={completionDate || emptyEducation.completionDate}
            onChange={(e) => handleInputChange(e, setCompletionDate)}
            className={
              completionDate || selectedEducation.completionDate
                ? ""
                : "to-edition"
            }
          />
        )}
      </Form.Group>
      <div className="edit-form-button-group">
        <div>
          <Button
            className="my-4"
            color="success"
            type="submit"
            disabled={isSubmmitDisabled}
          >
            Submit
          </Button>
          <Button type="button" onClick={() => closeEditView()}>
            CANCEL
          </Button>
        </div>
        {educationId && (
          <Button type="button" color="error" onClick={removeEducation}>
            Delete Education
          </Button>
        )}
      </div>
    </Form>
  );
}
