import { endpoints } from "./axios";
import axiosInstance from "./axiosClient";

export async function getCandidateData(candidateId: number) {
  try {
    return await axiosInstance
      .get(`${endpoints.candidates}/${candidateId}`)
      .then((response) => response.data);
  } catch (err) {
    console.error("No such candidate");
    throw err;
  }
}

export async function getWorkExperience(candidateId: number) {
  try {
    const url = `${endpoints.experiencesByCandidateId}/${candidateId}`;
    return await axiosInstance.get(url).then((response) => response.data);
  } catch (err) {
    console.error("No work Experience", err);
    throw err;
  }
}

export async function getEducations(candidateId: number) {
  try {
    const url = `${endpoints.educationsByCandidateId}/${candidateId}`;
    return await axiosInstance.get(url).then((response) => response.data);
  } catch (err) {
    console.error("No educations", err);
    throw err;
  }
}

export async function getSkills(candidateId: number) {
  try {
    const url = `${endpoints.skillsByCandidateId}/${candidateId}`;
    return await axiosInstance.get(url).then((response) => response.data);
  } catch (err) {
    console.error("No skills", err);
    throw err;
  }
}

export async function getCoderByteTests() {
  try {
    return await axiosInstance
      .get(endpoints.coderbyteTest)
      .then((response) => response.data);
  } catch (err) {
    console.error("No tests", err);
    throw err;
  }
}
