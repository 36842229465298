import axios from "axios";
import { getUrlConstantPath } from "../utils/getUrlConstantPath";

export const JWT_TOKEN = "jwt";
export const JWT_REFRESH_TOKEN = "jwt_refresh_token";
export const baseURL = "https://api.dev.recruitment-auto.bcf-software.com";

export function setLocalStorageItem(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

export function getLocalStorageItem(key: string) {
  return window.localStorage.getItem(key);
}

export default axios.create({
  baseURL: "https://api.dev.recruitment-auto.bcf-software.com",
});

export const endpoints = {
  authURL: "/authenticate",
  candidates: "/candidates",
  parse: "/parse",
  educations: "/educations",
  educationsByCandidateId: "/educations/candidate",
  experiences: "/experiences",
  experiencesByCandidateId: "/experiences/candidate",
  skills: "/skills",
  skillsByCandidateId: "/skills/candidate",
  exportPDF: "/exporter",
  exportDOCX: "/exporter/docx",
  coderbyteTest: "/coderbyte/test",
  asanaULR: "/asana",
  asanaTasks: "/asana/candidate/{candidateId}/task/{coderbyteTaskId}",
  refreshToken: "/refreshToken",
  deleteCandidate: "/candidates",
};

export const getHeader = async (path: string) => {
  const jwtToken = getLocalStorageItem(JWT_TOKEN);

  const validatedPath = getUrlConstantPath(path);

  return {
    [endpoints.authURL]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
    },
    [endpoints.candidates]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PATCH, POST, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.educations]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PATCH, POST, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.experiences]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PATCH, POST, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.skills]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PATCH, POST, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.coderbyteTest]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PATCH, POST, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.asanaULR]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PATCH, POST, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.exportPDF]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.exportDOCX]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.parse]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.refreshToken]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [endpoints.deleteCandidate]: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${jwtToken}`,
    },
    [""]: {},
  }[validatedPath];
};
