import { Box, Button, Modal, Snackbar } from "@mui/material";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { endpoints } from "../api/axios";
import axiosInstance from "../api/axiosClient";
import { getCoderByteTests } from "../api/axiosRequests";
import { useAppContext } from "../context/ContextProvider";
import {
  CandidateModalProps,
  CoderByteTestResponse,
} from "../ts/interfaces/app_interfaces";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const emptyTest: CoderByteTestResponse = {
  id: "",
  technology: "select",
  url: "",
};

export default function PostTask(props: CandidateModalProps) {
  const { open, handleCloseModal, candidateId } = props;
  const [tests, setTests] = useState<CoderByteTestResponse[]>([]);
  const [selectedTest, setSelectedTest] =
    useState<CoderByteTestResponse>(emptyTest);
  const { isAuthenticated, setIsCandidateChecked } = useAppContext();
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const handleCopyUrl = useCallback(() => {
    if (selectedTest.url) {
      setShowSnackbar(true);
      navigator.clipboard.writeText(selectedTest.url);
    }
  }, [selectedTest]);

  useEffect(() => {
    let isMounted = true;
    if (candidateId && isAuthenticated && open) {
      getCoderByteTests().then((data) => {
        if (isMounted) setTests(data);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, open, candidateId]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const postTaskToAsana = async () => {
      try {
        await axiosInstance.post(
          endpoints.asanaTasks
            .replace("{candidateId}", candidateId)
            .replace("{coderbyteTaskId}", selectedTest.id)
        );
      } catch (err) {
        console.error("No such candidate");
      }
    };
    postTaskToAsana()
      .then(() => setIsCandidateChecked((old) => !old))
      .then(() => handleCloseModal());
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="my-4">CREATE CODERBYTE TEST</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3 my-4"
              controlId="formCoderbyteTechnology"
            >
              <Form.Label>Technology</Form.Label>
              <Dropdown>
                <Dropdown.Toggle style={{ background: "#0d6efd" }}>
                  {selectedTest.technology || emptyTest.technology}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tests.map((test) => (
                    <Dropdown.Item
                      key={test.id}
                      onClick={() => setSelectedTest(test)}
                    >
                      {test.technology}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <Form.Group className="mb-3 my-4" controlId="formCoderbyteURL">
              <Form.Label>URL</Form.Label>
              <Form.Control
                onClick={handleCopyUrl}
                plaintext
                readOnly
                defaultValue={selectedTest.url || emptyTest.url}
              />
              <Snackbar
                message="Copied url to clipboard"
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={1000}
                onClose={() => setShowSnackbar(false)}
                open={showSnackbar}
              />
            </Form.Group>
            <Button
              className="my-4"
              color="success"
              type="submit"
              disabled={selectedTest.technology === "select"}
            >
              Invite Candidate
            </Button>
            <Button type="button" onClick={handleCloseModal}>
              CANCEL
            </Button>
          </Form>
        </Box>
      </Modal>
    </div>
  );
}
