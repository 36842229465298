import {
  WorkExperienceResponse,
  EducationResponse,
} from '../ts/interfaces/app_interfaces'

export const isWorkExperienceApproved = (
  workExperience: WorkExperienceResponse
) => {
  const { organization, jobDescription, startDate, isCurrent, endDate } =
    workExperience
  return (
    organization &&
    jobDescription &&
    startDate &&
    ((!isCurrent && endDate) || isCurrent)
  )
}

export const isEducationApproved = (education: EducationResponse) => {
  const { educationName, organization, startDate, isCurrent, completionDate } =
    education
  return (
    educationName &&
    organization &&
    startDate &&
    ((!isCurrent && completionDate) || isCurrent)
  )
}
