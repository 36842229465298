import { endpoints } from '../api/axios'

export function getUrlConstantPath(url: string): string {
  for (const key in endpoints) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (url.includes((endpoints as any)[key])) {
      return (endpoints as any)[key]
    }
  }
  return ''
}
